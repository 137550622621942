<template>

    <div class="sports-links" style="width: 25%">
        <router-link tag="a" to="/recharge" class="link" active-class="active_sports_link">코인충전</router-link>
        <router-link tag="span" to="" class="link fg">|</router-link>
        <router-link tag="a" to="/item_shop" class="link" active-class="active_sports_link">아이템샵</router-link>
        <router-link tag="span" to="" class="link fg">|</router-link>
        <router-link tag="a" to="/recharge_history" class="link" active-class="active_sports_link">충전내역</router-link>
    </div>
</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import {RECEIVE_DISPLAY_POSTION} from "../../store/mutation-types";

    export default {
        name: "ShopLinksComp",
        props: {
            displayPosition: {
                type: Number,
                default() {
                    return sportsConst.GAME_DISPLAYPOSITION_1x2;
                }
            },
        },
        data() {
            return {
                sportsConst,
            }
        },
        methods: {

        },
    }
</script>

<style scoped>
    .sports-links {
       width: 25%;
    }
</style>