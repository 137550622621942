<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <shop-links-comp></shop-links-comp>
                <div class="board_list">
                    <div class="logo_panel">
                        <div style="display:flex;flex-wrap:nowrap;justify-content: left;background-color: #515151;box-sizing: border-box;padding: 10px">
                            <div style="width: 80px"><img
                                    src="../../assets/images/item/oneplay/item_shop_icon_01.png" style="width: 50px;" alt="">
                            </div>
                            <div style="font-size: 10px;text-align: left;">
                                <p>원플레이 아이템샵에서 구매한 아이템으로 다양한 서비스를 이용하실 수 있습니다.</p>
                                <p>아이템을 구매하시면 해당 유형에 따라 사용하실 수 있습니다.</p>
                                <p>아이템 구매 시 아이템 사용 유형을 잘 확인하시고 구매 부탁드립니다.</p>
                            </div>
                        </div>
                        <h4 class="pay_title">아이템 샵</h4>
                        <div class="items">
                            <div class="it" v-for="(item,index) in list" :key="index">
                                <div class="it_image">
                                    <!--<img :src="item.imageUrl">-->

                                    <img :src="require(`@/assets/images/item/oneplay/${item.id}.png`)">
                                </div>
                                <div class="it_title">
                                    <div class="it_t1">{{item.name}}</div>
                                    <div class="it_t2">{{item.price|comma}} 코인</div>
                                </div>
                                <div class="it_ment">
                                    {{item.ment}}
                                </div>
                                <div class="it_foot" style="">
                                    <div style="display: flex; justify-content: left">
                                        <button class="btn_cacl" @click="minusBuyCount(item)">-</button>
                                        <button class="btn_cacl">{{item.buyCount}}</button>
                                        <button class="btn_cacl" @click="plusBuyCount(item)">+</button>
                                    </div>
                                    <div style="text-align: right;padding-right: 10px;box-sizing: border-box">
                                        <button @click="buyNow(item)" class="btn03"
                                                style="width: 80px;margin-top: 7px;height: 30px"><i
                                                class="fa fa-shopping-cart"></i> 구매하기
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>
</template>

<script>
    import SubTitle from "../../components/SubTitle";
    import RightBarComp from "../../components/RightBarComp";
    import Pagination from "../../components/pagenation/Pagination";
    import BoardWriter from "../../components/BoardWriter";
    import sportsConst from "../../common/sportsConst";
    import LeftBarComp from "../../components/LeftBarComp";
    import FootComp from "../../components/FootComp";
    import TopbarComp from "../../components/TopbarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import MarketLinksComp from "../../components/links/MarketeLinksComp";
    import {buyItem, getItemList} from "../../network/userRequest";
    import ShopLinksComp from "../../components/links/ShopLinksComp";

    export default {
        name: "ItemShop",
        components: {
            ShopLinksComp,
            MarketLinksComp,
            SportsLeftBarComp,
            RightBarBannerComp,
            TopbarComp, FootComp, LeftBarComp, BoardWriter, Pagination, RightBarComp, SubTitle
        },
        data() {
            return {
                sportsConst,
                pageNum: 1,
                pageSize: 20,
                total: 1,
                search: {},
                list: [],
            }
        },
        methods: {
            minusBuyCount(item) {
                if (item.buyCount <= 1) {
                    return false;
                }
                item.buyCount--;
            },
            plusBuyCount(item) {
                if (item.buyCount >= 99) {
                    return false;
                }
                item.buyCount++;
            },
            buyNow(item) {
                if (!this.$store.state.isLogin) {
                    this.$swal({
                        title: '로그인이 필요한 서비스 입니다.',
                        type: 'warning',
                        showCancelButton: false,
                        showConfirmButton: true
                    })
                    this.$router.push('/login')
                    return false;
                }
                this.$swal({
                    title: '`' + item.name + '` 을 ' + item.buyCount + '개 구매하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        buyItem(item).then(res => {
                            if (res.data.success) {
                                this.$swal({
                                    title: '구매성공',
                                    type: 'success',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                item.buyCount = 1
                                this.$store.dispatch('actionUserInfo')
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                });
            },
            initList() {
                getItemList(this.search, this.pageNum, this.pageSize).then(res => {
                    if (res.data.success) {
                        this.list = res.data.data
                    }
                })
            },

            pageChange(page) {
                this.pageNum = page
                this.initList()
            },
        },
        created() {
            this.initList();
        }

    }
</script>

<style scoped>
    .logo_panel{
     margin-top: 10px!important;
    }
    .items {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin: 20px auto;
    }

    .items .it {
        width: 30%;
        background-color: #2f2f2f;
        margin-bottom: 20px;
    }

    .it .it_image {
        width: 100%;
        box-sizing: border-box;
        padding: 20px 0;
    }

    .it .it_image img {
        height: 150px;
    }

    .it .it_title {
        text-align: left;
        box-sizing: border-box;
        padding-left: 20px;
        border-bottom: 1px solid #3c3c3c;
    }

    .it .it_t1 {
        line-height: 30px;
    }

    .it .it_t2 {
        line-height: 30px;
        font-size: 12px;
        color: #f4c600;
    }

    .it .it_ment {
        width: 100%;
        box-sizing: border-box;
        font-size: 11px;
        color: #9f9fa1;
        text-align: left;
        line-height: 40px;
        padding-left: 20px;
    }

    .it .it_foot {
        width: 100%;
        background-color: #0c0e0e;
        height: 44px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding-left: 10px;
    }

    .it .it_foot .btn_cacl {
        width: 30px;
        height: 30px;
        background-color: #202020;
        border: 1px solid #3c3c3c;
        line-height: 30px;
        margin-top: 7px;
        cursor: pointer;
    }

    @media screen and (max-width: 1024px) {
        .items .it {
            width: 100% !important;
        }
    }
</style>